<template>
<div id="addContacts">
  <div class="step-box">
    <h3>开启高效的招聘方式，你只需要 3步：</h3>
    <div class="steps">
      <div class="step-one">
        <p>
          <span>1</span>
          <label>创建名片</label>
        </p>
      </div>
      <div class="line lineGray"></div>
      <div class="step-two">
        <p>
          <span>2</span>
          <label>创建公司</label>
        </p>
      </div>
      <div class="line lineGray"></div>
      <div class="step-three">
        <p>
          <span>3</span>
          <label>发布第一个职位</label>
        </p>
      </div>
    </div>
  </div>
  <div class="publish">
    <el-form :model="positionModel" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="头像" class="avator-item">
        <el-upload class="avatar-uploader" :on-success="avatarSuccess" :action="baseUrl" :show-file-list="false">
          <img v-if="positionModel.headSculpture" :src="positionModel.headSculpture" class="avatar">
          <img class="uoloadPic" v-else src="@/assets/imgs/company/uoloadPic.png" />
        </el-upload>
      </el-form-item>
      <el-form-item label="姓名" prop="userName">
        <el-input v-model="positionModel.userName" placeholder="请输入您的姓名"></el-input>
      </el-form-item>
      <el-form-item label="我的公司" prop="enterpriseName">
        <el-autocomplete
          v-model="positionModel.enterpriseName"
          :fetch-suggestions="querySearchAsync"
          value-key="enterpriseName"
          placeholder="请输入我的公司"
          @select="selectCompany"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="我的职位" prop="position">
        <el-input v-model="positionModel.position" placeholder="请输入我的职位"></el-input>
      </el-form-item>
      <div class="saveBtn" @click="next">下一步</div>
    </el-form>
  </div>
</div>
</template>

<script>
import {getEnterpriseListByName,  addContacts, getContactsInfo,verificationEnterprise } from "@/api/index.js";
export default {
  name: "addContacts",
  data() {
    return {
      positionModel: {
        headSculpture: '',
        enterpriseName: '',
        position: '',
        userName: ''
      },
      rules: {
        userName: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        enterpriseName: [
          { required: true, message: '请输入我的公司', trigger: 'change' }
        ],
        position: [
          { required: true, message: '请输入我的职位', trigger: 'blur' }
        ],
      },
      baseUrl: '',
      companyList:[],
      companyListMap:[],
      companyName:'',
      loading:false,
      timeout:  null,
      enterpriseName:''
    }
  },
  mounted() {
    this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
    this.getContactsInfo()
    this.getEnterpriseListByName()
  },
  methods: {
    getEnterpriseListByName(){
      getEnterpriseListByName({
        enterpriseName:this.companyName 
      }).then(res=>{
        this.companyList = res.rows
      })
    },
    selectCompany(item){
      console.log(item)
    },
    querySearchAsync(queryString, cb){
      var results = queryString
        ? this.companyList.filter(this.createFilter(queryString))
        : this.companyList;
      cb(results);
    },
    createFilter(queryString){
      return state => {
        return (
          state.enterpriseName.toLowerCase().indexOf(queryString.toLowerCase()) > -1
        )
      }
    },
    avatarSuccess(res) {
      this.positionModel.headSculpture = res.url
    },
    verificationEnterprise(){
      verificationEnterprise({
        enterpriseName:this.positionModel.enterpriseName
      }).then(res=>{
        if(res.verificationEnterprise==1){
          this.$router.push({
            path: '/joinCompany',
            query: {
              enterpriseName: this.positionModel.enterpriseName
            }
          })
        }else{
          this.$router.push({
            path: '/addCompany',
            query: {
              enterpriseName: this.positionModel.enterpriseName
            }
          })
        }
      })
    },
    next() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addContacts(this.positionModel).then(res => {
            if (res.code == 200) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.verificationEnterprise()
            }
          })
        }
      });
    },
    getContactsInfo() {
      getContactsInfo().then(res => {
        var info = res.data
        this.positionModel.headSculpture = info.headSculpture
        this.positionModel.position = info.position
        this.positionModel.userName = info.userName
        this.positionModel.enterpriseName = info.enterpriseName
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#addContacts {
  width: 100%;
  height: 814px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
}

.step-box {
  padding-top: 31px;
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;

  h3 {
    font-size: 26px;
    font-weight: 400;
    color: #2A2C33;
    float: left;
    margin-top: 40px;
  }
}

.steps {
  float: right;
  height: 100px;

  .step-one {
    float: left;
    text-align: center;

    p {
      float: left;

      span {
        display: inline-block;
        width: 41px;
        height: 41px;
        background: #0077FF;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        color: #fff;
        font-size: 18px;
      }

      label {
        display: block;
        font-size: 16px;
        color: #0077FF;
        margin-top: 10px;
      }
    }
  }

  .line {
    width: 119px;
    height: 2px;
    background: #0077FF;
    border-radius: 1px;
    float: left;
    margin-top: 21px;
  }

  .lineGray {
    background: #999999;
  }

  .step-two {
    float: left;
    margin-left: 8px;
    text-align: center;
    cursor: pointer;

    p {
      float: left;

      span {
        display: inline-block;
        width: 41px;
        height: 41px;
        background: #999999;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        color: #fff;
        font-size: 18px;
      }

      label {
        display: block;
        font-size: 16px;
        color: #999999;
        margin-top: 10px;
      }
    }
  }

  .step-three {
    float: left;
    margin-left: 8px;
    cursor: pointer;

    p {
      float: left;

      span {
        display: inline-block;
        width: 41px;
        height: 41px;
        background: #999999;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        color: #fff;
        font-size: 18px;
      }

      label {
        display: block;
        font-size: 16px;
        color: #999999;
        margin-top: 10px;
        position: relative;
        right: 70px;
      }
    }
  }
}

.publish {
  width: 705px;
  margin: 31px auto;
  height: 430px;
  background: #FFFFFF;
  padding: 42px 160px 41px 200px;
  border-radius: 1px 10px 10px 10px;

  .uoloadPic {
    width: 66px;
    height: 66px;
  }

  .avatar-uploader,
  .avatar {
    height: 66px;
    width: 66px;
  }

  .saveBtn {
    width: 592px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    background: #0077FF;
    border-radius: 5px;
    margin-left: 100px;
    cursor: pointer;
  }
}
</style><style lang="scss">
.avator-item {
  .el-form-item__label {
    margin-top: 13px;
  }
}

.publish {
  .el-form-item {
    margin-bottom: 40px;
  }

  .el-form-item__label {
    font-size: 16px;
    color: #000000;
  }

  .el-input__inner {
    width: 592px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }
}
</style>
